<template>
  <Popup
    ref="AssignDriver"
    :title="$t('single_order_assign_to_driver_button')"
    :width="670"
    :height="570"
  >
    <template v-slot:body>
      <!-- Loading Indicator -->
      <div class="d-flex justify-content-center">
        <LoadingIndicator :active="loading" />
      </div>
      <div v-show="load_error">
        <div class="justify-content-center text-center text-danger my-4">
          <h4>{{ load_error }}</h4>
        </div>
      </div>
      <!-- Main Body -->
      <!-- geoCoder for getting location -->
      <div style="min-height: 300px">
        <div
          class="flex-wrap h-100 bg-light border"
          style="overflow: auto"
          :class="{ 'd-flex': !loading, 'd-none': loading }"
        >
          <!-- <div class="col-md-7 col-12 p-3">
									<div class="d-flex">
										<input type="text" class="p-2 w-100 border-secondary" placeholder="Enter delivery address"
											v-model="locationText" @keydown.enter="getGeocodeLocation" />
										<button class="btn btn-outline-primary" @click="getGeocodeLocation">
											Submit
										</button>
									</div>
									<div class="bg-primary border my-1" style="min-height: 300px">
										<Map :height="300" />
									</div>
								</div> -->

          <!-- List of available drivers -->
          <div class="w-100 bg-secondary text-white border">
            <div>
              <!-- Drivers Title -->
              <div class="py-2">
                <p style="font-size: 20px; text-align: center; margin: 0">
                  <strong>{{
                    $t("single_order_assign_driver_popup_nearby_drivers")
                  }}</strong>
                </p>
              </div>

              <!-- Drivers List -->
              <div
                class="bg-white text-black driver-list"
                style="overflow: auto; min-height: 300px"
              >
                <div
                  class="d-flex justify-content-between option-hover p-3"
                  :class="{
                    'selected-driver': selectedDriver == index,
                  }"
                  @click="
                    () => {
                      selectedDriver = index;
                    }
                  "
                  v-for="(driver, index) in availableDrivers"
                  :key="index"
                >
                  <span>
                    <span class="me-3"><i class="fas fa-location"></i></span>
                    <!-- {{ driver }} -->
                    <span>{{ driver.first_name }} {{ driver.last_name }}</span>
                  </span>

                  <span>{{ driver.phone_number }}</span>
                </div>
                <div
                  class="text-danger h4 w-100 d-flex align-items-center justify-content-center"
                  style="height: 300px"
                  v-if="availableDrivers.length == 0"
                >
                  No drivers are available
                </div>
              </div>
              <!-- Drivers Footer -->
              <div class="px-2 py-1" style="min-height: 50px">
                <div
                  class="d-flex justify-content-between"
                  v-if="availableDrivers.length != 0"
                >
                  <!-- Left Arrow -->
                  <button class="btn" @click="prevPage">
                    <i class="fa fa-arrow-left" aria-hidden="true"></i>
                  </button>
                  <!-- Current Page -->
                  <div class="align-items-center d-flex px-2">
                    {{ current_page }}/{{ pages }}
                  </div>
                  <!-- Right Arrow -->
                  <button class="btn" @click="nextPage">
                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="p-2 d-flex justify-content-around">
        <button
          class="btn btn-success"
          @click="AssignDriver"
          :class="{ disabled: selectedDriver == null }"
        >
          Assign Driver
        </button>
        <button class="btn btn-danger" @click="closeAvailableDrivers">
          Cancel
        </button>
      </div>
    </template>
  </Popup>
</template>

<script>
// Components
import Popup from "@/components/Popup.vue";
import Map from "@/components/Map.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";

// Services
import DriversService from "@/services/drivers.js";
import CommonService from "@/services/common.js";

export default {
  name: "AssignDriver",
  props: {
    order_id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    Popup,
    Map,
    LoadingIndicator,
  },
  data() {
    return {
      loading: false,
      load_error: "",

      locationText: "",
      // location: { lat: 8.9806034, lng: 38.7577605 },

      selectedDriver: null,

      current_page: 1,
      pages: 0,
      // Available Drivers
      availableDrivers: [],
    };
  },
  methods: {
    nextPage() {
      if (this.current_page != this.pages) {
        this.GetAvailableDrivers(this.current_page + 1);
      }
    },
    prevPage() {
      if (this.current_page != 1) {
        this.GetAvailableDrivers(this.current_page - 1);
      }
    },
    ShowAvailableDriversPopup() {
      this.$refs.AssignDriver.showPopup();
      //Get Available Drivers
      this.GetAvailableDrivers(this.current_page);
    },
    closeAvailableDrivers() {
      this.$refs.AssignDriver.closePopup();
    },
    // TODO: Get Driver Location
    getDriverLocations() {
      const driverLocations = [];
    },
    GetAvailableDrivers(page) {
      // Start loading indicator
      this.loading = true;
      this.load_error = "";

      DriversService.getUnoccupiedDriver(page).then(
        (response) => {
          console.log(response);

          this.loading = false;
          this.availableDrivers = response.data.data.drivers;

          // Pagination Settings
          this.current_page = page;
          this.pages = response.data.data.total_pages;
        },
        (error) => {
          this.loading = false;
          this.load_error =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getGeocodeLocation() {
      // Start loading indicator
      this.loading = true;
      this.load_error = "";
      CommonService.getGeocode(this.locationText).then(
        (response) => {
          this.loading = false;
          console.log(response);
          this.location = response.data.data;
        },
        (error) => {
          console.log(error);
          this.loading = false;
          this.load_error =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    AssignDriver() {
      // Start loading indicator
      this.loading = true;
      this.load_error = "";

      console.log(
        `the driver information to be assigned is :${{
          driver_id: this.availableDrivers[this.selectedDriver].id,
          order_id: this.order_id,
        }}`
      );
      DriversService.assignDriver({
        driver_id: this.availableDrivers[this.selectedDriver].id,
        order_id: this.order_id,
      }).then(
        (response) => {
          this.loading = false;
          console.log(response);
          this.$emit("assigned");
          this.closeAvailableDrivers();
        },
        (error) => {
          this.loading = false;
          this.load_error =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          console.log(error);
        }
      );
    },
  },
};
</script>

<style scoped>
.option-hover {
  cursor: pointer;
}

.option-hover:hover {
  background: #ccc;
}

.selected-driver {
  border: 1px solid #444;
  background-color: #aaa;
}

.driver-list::-webkit-scrollbar {
  width: 15px;
}

.driver-list::-webkit-scrollbar-track {
  background-color: #ddd;
}

.driver-list::-webkit-scrollbar-thumb {
  border: 5px solid transparent;
  border-radius: 100px;
  background-color: #999;
  background-clip: content-box;
}

.driver-list::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}
</style>
