<template>
	<div>
		<GMapMap
			:center="comp_center"
			:zoom="15"
			map-type-id="terrain"
			:options="{
				restriction: {
					latLngBounds: {
						north: 14.95943,
						south: 3.42206,
						east: 47.78942,
						west: 32.95418,
					},
					strictBounds: true,
				},
			}"
			ref="gmap"
			:style="{ height: height + 'px' }"
		>
			<GMapCluster>
				<GMapMarker
					:key="index"
					v-for="(m, index) in comp_markers"
					:position="m.position"
					:clickable="true"
					:draggable="false"
					@click="center = m.position"
				/>
			</GMapCluster>
		</GMapMap>
	</div>
</template>

<script>
export default {
	name: "Map",
	props: {
		center: {
			type: Object,
		},
		markers: {
			type: Array,
			default: [],
		},
		height: {
			type: Number,
			default: 400,
		},
	},
	methods: {},
	computed: {
		comp_center() {
			console.log(this.center);
			if (this.center?.lat != null && this.center?.lng != null) {
				return this.center;
			} else {
				return { lat: 8.9806034, lng: 38.7577605 };
			}
		},
		comp_markers() {
			if (this.markers) {
				return this.markers;
			} else {
				return [{ position: { lat: 8.9806034, lng: 38.7577605 } }];
			}
		},
	},
	mounted() {
		this.$refs.gmap.$mapPromise.then((map) => {
			console.log("gmap zoom", map.zoom);
			map.addListener("zoom_changed", function (data) {
				console.log("zoom", map.zoom);
			});
		});
	},
};
</script>
