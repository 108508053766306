import axios from "axios";
import authHeader from "../helpers/auth-header";

const API_COMMON_URL = process.env.VUE_APP_API_URL;

class CommonService {
	getGeocode(place_name) {
		return axios.post(
			API_COMMON_URL + "common/admin/geocode",
			{
				place_name: place_name,
			},
			{
				headers: authHeader(),
			}
		);
	}
}

export default new CommonService();
