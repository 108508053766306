<template>
  <div class="d-flex flex-wrap justify-content-around">
    <!-- Days -->
    <div class="col-6 col-lg-4 col-xl-3 px-3 py-2">
      <div
        class="rounded shadow-sm border d-flex flex-column"
        style="height: 120px"
      >
        <div class="h2 d-flex justify-content-center align-items-center h-100">
          <transition name="slide-fade">
            <div :key="days">
              {{ days }}
            </div>
          </transition>
        </div>
        <div class="text-center theme-background-secondary font-weight-bold">
          {{ $t("single_order_time_days") }}
        </div>
      </div>
    </div>
    <div class="col-6 col-lg-4 col-xl-3 px-3 py-2">
      <div
        class="rounded shadow-sm border d-flex flex-column"
        style="height: 120px"
      >
        <div class="h2 d-flex justify-content-center align-items-center h-100">
          <transition name="slide-fade">
            <div :key="hours">{{ hours }}</div>
          </transition>
        </div>
        <div class="text-center theme-background-secondary font-weight-bold">
          {{ $t("single_order_time_hours") }}
        </div>
      </div>
    </div>
    <div class="col-6 col-lg-4 col-xl-3 px-3 py-2">
      <div
        class="rounded shadow-sm border d-flex flex-column"
        style="height: 120px"
      >
        <div class="h2 d-flex justify-content-center align-items-center h-100">
          <transition name="slide-fade">
            <div :key="minutes">
              {{ minutes }}
            </div>
          </transition>
        </div>
        <div class="text-center theme-background-secondary font-weight-bold">
          {{ $t("single_order_time_minutes") }}
        </div>
      </div>
    </div>

    <div class="col-6 col-lg-4 col-xl-3 px-3 py-2">
      <div
        class="rounded shadow-sm border d-flex flex-column"
        style="height: 120px"
      >
        <div class="h2 d-flex justify-content-center align-items-center h-100">
          <transition name="slide-fade">
            <div :key="seconds">{{ seconds }}</div>
          </transition>
        </div>
        <div class="text-center theme-background-secondary font-weight-bold">
          {{ $t("single_order_time_seconds") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Countdown",
  props: {
    startTime: {
      type: Date,
      default: function () {
        return new Date(2000, 1, 1);
      },
    },
    endTime: {
      type: Date,
      default: function () {
        return new Date();
      },
    },
    nowTime: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      interval: null,

      // Time Data
      milliseconds: null,
      seconds: null,
      minutes: null,
      hours: null,
      days: null,
    };
  },
  methods: {
    CalculateTimeDifference(date1, date2) {
      var difference = date1.getTime() - date2.getTime();
      return {
        milliseconds: Math.floor((difference % 1000) / 100),
        seconds: Math.floor((difference / 1000) % 60),
        minutes: Math.floor((difference / (1000 * 60)) % 60),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      };
    },
    // Interval where the time difference is measured every second
    startClock() {
      // Update the time every second
      this.interval = setInterval(() => {
        this.setElapsedTime();
      }, 1000);
    },
    stopClock() {
      clearInterval(this.interval);
    },
    setElapsedTime() {
      const elapsedTime = this.CalculateTimeDifference(
        this.nowTime ? new Date() : this.endTime,
        this.startTime
      );
      // update values in clock
      this.milliseconds = elapsedTime.milliseconds;
      this.seconds = elapsedTime.seconds;
      this.minutes = elapsedTime.minutes;
      this.hours = elapsedTime.hours;
      this.days = elapsedTime.days;
    },
  },
  mounted() {
    this.setElapsedTime();
  },
  beforeUnmount() {
    // prevent memory leak
    clearInterval(this.interval);
  },
};
</script>

<style scoped>
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-in;
}

.slide-fade-leave-active {
  transition: all 0.5s ease-out;
}

.slide-fade-enter-from {
  transform: translateX(30px);
  opacity: 0;
}
.slide-fade-leave-to {
  transform: translateX(-30px);
  opacity: 0;
}
</style>
