<template>
  <div class="d-flex rounded shadow-sm border my-2 mx-1">
    <div class="rounded col-3 col-md-3 col-lg-4 py-4 px-1 text-center theme-background-secondary"
      style="min-width: 100px">
      <strong>{{ title }}</strong>
    </div>
    <div class="rounded col-9 col-md-9 col-lg-8 d-flex align-items-center px-3 flex-wrap" style="min-width: 100px">
      <slot name="data" :data="data">
        {{ data }}
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoBox",
  props: {
    title: {
      type: String,
      default: "",
    },
    data: null,
  },
};
</script>
