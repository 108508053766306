import axios from "axios";
import authHeader from "../helpers/auth-header";

const API_DRIVERS_URL = process.env.VUE_APP_API_URL + "driver";

class DriversService {
	getDrivers(page) {
		console.log("Page:", page);
		return axios.get(API_DRIVERS_URL + "/all/" + page, {
			headers: authHeader(),
		});
	}

	getSingleDriver(id) {
		// console.log(API_DRIVERS_URL + "/admin/" + id);
		return axios.get(API_DRIVERS_URL + "/admin/" + id, {
			headers: authHeader(),
		});
	}

	getUnoccupiedDriver(page) {
		return axios.get(API_DRIVERS_URL + "/available/" + page, {
			headers: authHeader(),
		});
	}

	assignDriver(assign) {
		return axios.post(API_DRIVERS_URL + "/assign", assign, {
			headers: authHeader(),
		});
	}
}

export default new DriversService();
